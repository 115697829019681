import React from "react"
import Layout from "../components/layout"

export default function about() {
  return (
    <Layout>
      <h1>Hello from Gao</h1>
    </Layout>
  )
}
